new Splide('.splide', {
	type: 'loop',
	arrows: false,
	perPage: 3,
	focus: 'center',
	autoplay: true,
	perMove: 1,
	breakpoints: {
		840: {
			perPage: 1
		}
	}
}).mount()