window.addEventListener('scroll', () => {
	const logo = document.querySelector('.nav__logo-link')
	const sticky = document.querySelector('.sticky')
	if (window.scrollY > window.innerHeight / 2) {
		logo.classList.add('scrolled')
		sticky.classList.add('scrolled')
	} else {
		logo.classList.remove('scrolled')
		sticky.classList.remove('scrolled')
	}
})